<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.type.label')"
                label-for="type"
                :state="errors && errors.type ? false : null"
              >
                <v-select
                  id="type"
                  v-model="item.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
                />
                <b-form-invalid-feedback v-if="errors && errors.type">
                  {{ errors.type[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.logic.label')"
                label-for="logic"
                :state="errors && errors.logic ? false : null"
              >
                <v-select
                  id="logic"
                  v-model="item.logic"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusLogicOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="logic"
                />
                <b-form-invalid-feedback v-if="errors && errors.logic">
                  {{ errors.logic[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.color.label')"
                label-for="color"
              >
                <b-form-input
                  id="color"
                  v-model="item.color"
                  :state="errors && errors.color ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.color">
                  {{ errors.color[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="false"
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.icon.label')"
                    :label-for="'description.' + language.code"
                    :state="errors && errors['description.' + language.code] ? false : null"
                  >
                    <b-form-textarea
                      :id="'description.' + language.code"
                      v-model="item.description[language.code]"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'statuses-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/admin/statuses/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'statuses-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        type: this.item.type,
        logic: this.item.logic,
        color: this.item.color,
      }

      this.$http.put(`/api/admin/statuses/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'statuses-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        type: item.type,
        logic: item.logic,
        color: item.color,
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
      })

      return data
    },
  },
}
</script>
